import React from "react"
import { Seo } from "../../components/seo"
import { Link } from "@reach/router"
import { ServiceSectionEs } from "../../components/es/ServicesSectionEs"

const ProcessEs = () => {
  return (
    <>
      <Seo title={`Pagina de procesos en Mexico`} language={"es"} />
      <section className="container english-page-body">
        <div className="row row-en">
          <div className="en-pages">
            <h1>Proceso de introducción laboral</h1>
            <div className="job-intro-process">
              <ol className="mb-70">
                <li>
                  <h2>1. Consulta</h2>
                  <p className="text">
                    Envíe su perfil mediante el formulario de solicitud haciendo
                    click en “Consulta gratuita”. Nos pondremos en contacto con
                    usted en un plazo de tres días hábiles a partir de su
                    consulta.
                  </p>
                  <Link
                    to="/es/job-form"
                    className="free-consultation-btn mt-30"
                  >
                    <button>Consulta gratuita</button>
                  </Link>
                </li>
                <li>
                  <h2>2. Asesoramiento (Reunión ONLINE)</h2>
                  <p className="text">
                    Tendrá una reunión ONLINE con uno de nuestros asesores para
                    conocer más de su trayectoria profesional, objetivos y
                    preferencias. Además de brindarle asesoría profesional para
                    juntos descubrir y definir las condiciones ideales para su
                    nuevo empleo.
                  </p>
                  <ul className="list">
                    <li>
                      Es posible programar las reuniones ONLINE de lunes a
                      viernes, de 9 a.m. a 6 p.m. Si estos horarios no le
                      resultan convenientes, infórmenos para acordar un horario
                      especial.
                    </li>
                    <li>
                      Prepare su Curriculum Vitae (CV); de preferencia en inglés
                      y envíelo antes de la reunión. Puede descargar nuestras y
                      plantillas si es necesario.
                    </li>
                    <li>
                      Plataforma para la reunión ONLINE: Google Meet o ZOOM.
                    </li>
                  </ul>
                </li>
                <li>
                  <h2>3. Información de empleos / vacantes</h2>
                  <p className="text">
                    Basándonos en su experiencia y habilidades, le compartiremos
                    información sobre atractivos empleos respetando sus
                    preferencias. Una vez confirmado su interés en el empleo,
                    apoyaremos en el proceso de postulación/selección.
                  </p>
                </li>
                <li>
                  <h2>4. Solicitud de empleo (postulación)</h2>
                  <p className="text">
                    Una vez que encontremos un empleo que se ajuste a sus
                    preferencias, enviaremos su CV y un perfil resumido a la
                    empresa para comenzar el proceso de selección de documentos.
                    Si es necesario, también le brindaremos asesoramiento sobre
                    cómo preparar su CV.
                  </p>
                </li>
                <li>
                  <h2>5. Entrevistas</h2>
                  <p className="text">
                    Después de pasar la revisión de documentos, concertaremos
                    entrevistas con la empresa. Nosotros nos encargaremos de
                    toda la comunicación con la empresa, incluida la
                    programación de entrevistas. Si tiene alguna pregunta o
                    inquietud durante el proceso de selección, no dude en
                    consultarnos en cualquier momento.
                  </p>
                </li>
                <li>
                  <h2>6. Oferta e ingreso</h2>
                  <p className="text">
                    Tras aprobar todas las entrevistas, estaremos recibiendo la
                    carta de oferta de trabajo por parte de la empresa. Tras
                    aceptar la oferta de trabajo, usted iniciará la comunicación
                    directa con la empresa hasta tu fecha de incorporación. No
                    obstante, para garantizar que sea fluido el proceso,
                    seguiremos brindándole apoyo durante todo el proceso hasta
                    confirmar su ingreso a la empresa.
                  </p>
                </li>
                <li>
                  <h2>7. Después del ingreso</h2>
                  <p className="text">
                    Seguimos a su servicio para cualquier consulta que tenga.
                    Incluso, nos comprometemos a dar seguimiento para validar
                    que esté siendo satisfactoria su experiencia en el nuevo
                    empleo.
                  </p>
                </li>
              </ol>
              <ServiceSectionEs />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ProcessEs
